import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import IotImage from "../../assets/images/services/iot.png"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="IoT Consulting Company in India"
                description="There are solutions for the public sector, transportation, energy, and utilities, as well as healthcare and education. Digital workplaces that are state-of-the-art and have cloud-based communication and collaboration capabilities." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={IotImage} alt="Custom Software Development" className="image" />
                    </div>
                    <div className="right-sec">
                        <h1>IoT Consulting Services</h1>
                        <p className="mt-2">
                            Our IoT Consulting Services are designed to help businesses leverage the power of the Internet of Things (IoT) to drive innovation, improve operational efficiency, and create new revenue streams. With the proliferation of connected devices and sensors, IoT technology has the potential to transform industries and unlock new opportunities for growth.
                        </p>
                    </div>
                </div>
                <div className="service-sub-container">
                    <h3>Key Services</h3>
                    <ul className="mt-3">
                        <li><strong> IoT Strategy Development</strong>: Define a comprehensive IoT strategy aligned with your business goals and objectives.</li>
                        <li className="mt-2"><strong> Technology Assessment and Selection</strong>: Evaluate IoT platforms, protocols, and technologies to determine the best fit for your needs.</li>
                        <li className="mt-2"><strong> Architecture Design</strong>: Design scalable and secure IoT architectures that meet performance, reliability, and data privacy requirements.</li>
                        <li className="mt-2"><strong>Prototyping and Proof of Concept</strong>: Build prototypes and proof-of-concept solutions to validate ideas and demonstrate feasibility.</li>
                        <li className="mt-2"><strong>Implementation and Integration</strong>: Develop and deploy end-to-end IoT solutions, including device connectivity, data processing, and application integration.</li>
                        <li className="mt-2"><strong> Data Analytics and Insights</strong>: Analyze IoT data to gain actionable insights and drive informed decision-making.</li>
                        <li className="mt-2"><strong>Security and Compliance</strong>: Implement robust security measures and ensure compliance with industry regulations to protect IoT systems and data.</li>
                        <li className="mt-2"><strong> Support and Maintenance</strong>: Provide ongoing support and maintenance to keep IoT systems running smoothly and securely.</li>
                    </ul>
                    <h3 className="mt-4">How It Works</h3>
                    <p className="mt-2">
                        Our IoT consulting process begins with a thorough assessment of your business objectives, existing infrastructure, and IoT readiness. Based on our findings, we develop a customized IoT strategy and implementation plan tailored to your specific needs and requirements. Our team of experienced IoT consultants then works closely with you to execute the plan, from design and development to deployment and beyond.
                    </p>
                    <h3>Why Choose Us?</h3>
                    <p className="mt-2">
                        With years of experience in IoT consulting and a proven track record of successful projects across various industries, our team has the expertise and resources to help you unlock the full potential of IoT technology. Whether you're looking to optimize operations, improve customer experiences, or create new revenue streams, we're here to guide you every step of the way.
                    </p>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query IOTSolutionsPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
